import React from 'react'
import {
    Box,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { UnderlinedLink } from '@shared/components/Links/UnderlinedLink'
import { ChainHeaderRow } from './ChainHeaderRow'
import { RegionsContent } from './RegionsContent'
import { Routes } from '@shared/constants/routes'
import { TableContainer } from '@shared/components/Table/styledComponents'
import { translate } from '@utils/translationUtils'
import { checkAccess } from '@utils/userUtils'
import { UserPermissionsEnum } from '@enums/UserPermissionsEnum'

export const ChainTable = ({ chains, userPermissions = [] }) => {
    const canCreateChain = checkAccess(
        userPermissions,
        UserPermissionsEnum.CHAIN_CREATE
    )

    const renderChains = () =>
        chains?.map(chain => {
            const { id, name, slug, image, regions } = chain

            return (
                <Box mb={3} key={id}>
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <ChainHeaderRow
                                {...{ name, slug, image, userPermissions }}
                            />
                        </AccordionSummary>

                        <AccordionDetails>
                            <RegionsContent
                                {...{ slug, regions, chain, userPermissions }}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )
        })

    return (
        <TableContainer>
            {chains.length ? renderChains() : null}

            {canCreateChain && (
                <UnderlinedLink
                    text={translate('chain.addNew')}
                    url={Routes.createChain}
                />
            )}
        </TableContainer>
    )
}
