import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import { UnderlinedLink } from '@shared/components/Links/UnderlinedLink'
import { Row } from '@shared/components/Table/Raw'
import { TableContentContainer } from '@shared/components/Table/styledComponents'
import { translate } from '@utils/translationUtils'
import { getRouteWithSlug, getRouteWithRegionId } from '@utils/routeUtils'
import { Routes } from '@shared/constants/routes'
import { getKey } from '@utils/arrayUtils'
import { UserPermissionsEnum } from '@enums/UserPermissionsEnum'
import { checkAccess } from '@utils/userUtils'
import {
    DndContext,
    closestCenter,
    useSensor,
    useSensors,
    PointerSensor,
} from '@dnd-kit/core'
import {
    SortableContext,
    useSortable,
    arrayMove,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ClinicsContent } from './ClinicsContent'
import { Title } from './Title'
import { DotsNine } from '../../../../node_modules/phosphor-react/dist/index'
import { useDispatch } from 'react-redux'
import { updateRegionOrder } from '@store/reducers/regions'

const SortableItem = ({ region, canEditRegion }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: region.id })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        marginBottom: '8px',
        backgroundColor: '#fff',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    }

    return (
        <div
            ref={setNodeRef}
            className="w-full"
            style={style}
            {...attributes}
            {...listeners}
        >
            <Row
                firstCellContent={
                    <div className="flex items-center gap-4 pb-4">
                        <DotsNine size={32} />
                        <Title text={region.name} />
                    </div>
                }
                secondCellContent={
                    canEditRegion &&
                    region.name !== 'No Region' && (
                        <UnderlinedLink
                            text={translate('region.editRegion')}
                            url={getRouteWithRegionId(
                                Routes.editRegion,
                                region.id
                            )}
                        />
                    )
                }
                thirdCellContent={2}
                marginTop={1}
            />
            <ClinicsContent {...{ clinics: region.clinics }} />
        </div>
    )
}

export const RegionsContent = ({ slug, chain, regions, userPermissions }) => {
    const dispatch = useDispatch()
    const [regionList, setRegionList] = useState([])

    useEffect(() => {
        const sortedRegions = [...regions].sort((a, b) => {
            if (a.order === undefined) return 1
            if (b.order === undefined) return -1
            return a.order - b.order
        })
        setRegionList(sortedRegions)
    }, [regions])

    const canCreateRegion = checkAccess(
        userPermissions,
        UserPermissionsEnum.REGION_CREATE
    )

    const canEditRegion = checkAccess(
        userPermissions,
        UserPermissionsEnum.REGION_UPDATE
    )

    const sensors = useSensors(
        useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
    )

    const handleDragEnd = event => {
        const { active, over } = event
        if (!over || active.id === over.id) return

        const oldIndex = regionList.findIndex(region => region.id === active.id)
        const newIndex = regionList.findIndex(region => region.id === over.id)

        const newOrder = arrayMove(regionList, oldIndex, newIndex)
        setRegionList(newOrder)
        updateOrder(newOrder)
    }

    const updateOrder = updatedRegions => {
        const formData = new FormData()
        formData.append('chain_id', chain.id)

        updatedRegions.forEach((region, index) => {
            if (region.id !== undefined && region.id !== null) {
                formData.append(`orders[${region.id}]`, index + 1)
            }
        })

        dispatch(updateRegionOrder(formData))
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <div className="w-full">
                <SortableContext
                    items={regionList.map(region => region.id)}
                    strategy={verticalListSortingStrategy}
                >
                    <TableContentContainer>
                        {regionList.map(region => (
                            <SortableItem
                                key={region.id}
                                region={region}
                                canEditRegion={canEditRegion}
                            />
                        ))}
                    </TableContentContainer>
                </SortableContext>

                {canCreateRegion && (
                    <UnderlinedLink
                        text={translate('region.addNew')}
                        url={getRouteWithSlug(Routes.createRegion, slug)}
                    />
                )}
            </div>
        </DndContext>
    )
}
