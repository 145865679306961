import React, { useEffect } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { translate } from '@utils/translationUtils'
import { getRouteWithId } from '@utils/routeUtils'
import { Routes } from '@shared/constants/routes'
import { useParams } from 'react-router'
import { getClinicInfo } from '@store/reducers/clinics'
import { StyledTitle } from '@containers/clinics/styledComponents'
import { CheckIcon } from '@svg/index'
import Box from '@material-ui/core/Box'

const Clinic = ({ store, dispatch }) => {
    const { id } = useParams()

    const { clinic, loading } = store.clinics
    const {
        address1,
        address2,
        country,
        image,
        chain,
        phone,
        name,
        city,
        type,
        zip,
    } = clinic

    useEffect(() => {
        dispatch(getClinicInfo(id))
    }, [])

    if (!clinic.id) {
        return <SidebarLayout loading={loading} />
    }

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader
                title={translate('clinic.clinicInfo')}
                navigateTo={getRouteWithId(Routes.editClinic, id)}
                navBtnText={translate('clinic.editClinic')}
            />

            <Box display="flex" justifyContent="space-between">
                <Box marginRight={4}>
                    <StyledTitle
                        semibold
                    >{`${chain.name} - ${name}`}</StyledTitle>
                    <StyledTitle
                        regular
                    >{`${address1} ${address2}`}</StyledTitle>
                    <StyledTitle regular>{`${zip} ${city}`}</StyledTitle>
                    <StyledTitle regular>{country}</StyledTitle>
                    {phone && <StyledTitle regular>{phone}</StyledTitle>}

                    <Box marginTop={2}>
                        <StyledTitle semibold>
                            {translate('clinic.type')}
                        </StyledTitle>
                        <StyledTitle regular>
                            {type} <CheckIcon />
                        </StyledTitle>
                    </Box>
                </Box>

                {image && (
                    <img
                        src={encodeURI(image)}
                        loading="lazy"
                        alt="logo"
                        width={450}
                    />
                )}
            </Box>
        </SidebarLayout>
    )
}

export default withConnectedStore(Clinic)
